<template>
  <div class="w-full h-auto text-center text-primary-1000 text-2xl mx-auto py-8 bg-primary-100 mt-8 px-4">
    {{ t('footer.newsletter') }}
    <p v-if="showMessage" class="text-sm mt-2 -mb-2 font-bold bg-white rounded-md p-4 max-w-fit mx-auto">
      {{ showMessage ? message : '' }}
    </p>
    <form v-if="!showMessage" @submit.prevent="handleSubscribe" class="relative flex items-center justify-center pt-2">
      <input
        class="w-2/3 text-sm py-2 px-4 flex items-center text-left text-black rounded-l-md focus:outline-none rtl:text-right"
        :placeholder="t('forms.placeholders.newsletter')"
        type="email"
        required
        v-model="email"
      />
      <button class="bg-white pr-2 py-0.5 rounded-r-md" type="submit">
        <Icon name="vaadin:paperplane" class="w-5 h-5 text-primary-1000" />
      </button>
    </form>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n();

const email = ref('');
const showMessage = ref(false);
const message = ref('');

async function handleSubscribe() {
  await useFetch('/api/newsletter/subscribe', {
    method: 'POST',
    body: {
      email: email.value,
    },
    onResponse({ response }) {
      if (response.ok) {
        showMessage.value = true;
        message.value = t('responses.newsletter.success');
      } else {
        showMessage.value = true;
        message.value = t('responses.newsletter.error');
      }
    },
  });
}
</script>
